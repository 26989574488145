<template>
  <div class='surveys-view'>
    <b-overlay
      :show="loading"
      rounded
      opacity="0.7"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block w-100 py-3"
    >
      <div class='d-flex py-3 justify-content-between align-items-end flex-wrap w-100'>
        <div class='mt-2'>
          <reports-downloads-header @change-api-key='handleChangeReportsDownloads' />
        </div>

        <div>
          <base-button
            style='height: 48px'
            @click="handleChangeReportsDownloads()"
          >
            <b>ATUALIZAR</b>
          </base-button>
        </div>
      </div>

      <reports-downloads-table
        :reportsDownloads='reportsDownloads'
        :reportsDownloadsMeta='reportsDownloadsMeta'
        @change-reportsDownloads='handleChangeReportsDownloads'
      />
      <p class='text-center' v-if='reportsDownloads.length < 1'>Nenhum item encontrado</p>

      <template v-slot:overlay>
        <div class="text-center">
          <b-spinner style="width: 3rem; height: 3rem;" variant='primary' label="Large Spinner"></b-spinner>
          <h6 class='text-primary'>{{ loadingText }}</h6>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ReportsDownloadsTable from '@/components/ReportsDownloads/Table'
import ReportsDownloadsHeader from '@/components/ReportsDownloads/Header'
import { mapActions } from 'vuex'

export default {
  name: 'ReportsDownloadsView',
  data() {
    return {
      interval: null,
      reportsDownloads: [],
      reportsDownloadsMeta: {},
      loading: false,
      loadingText: 'Buscando...',
    }
  },
  components: {
    ReportsDownloadsTable,
    ReportsDownloadsHeader
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
  },
  watch: {},
  methods: {
    ...mapActions('reportsDownloads', {
      handleGetReportDownloads: 'handleGetReportDownloads'
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive',
    }),
    async handleChangeReportsDownloads(payload) {
      const params = {
        ...this.reportsDownloadsMeta,
        ...payload
      }
      this.loading = true
      const { meta, data } = await this.handleGetReportDownloads(params)
      this.reportsDownloadsMeta = meta ? meta : {}
      this.reportsDownloads = data ? data.reportsDownloads : []
      this.loading = false
    },
    async startTimer(){
      this.interval = setInterval(async() => {
        let params = {
          ...this.reportsDownloadsMeta,
        }
        await this.handleChangeReportsDownloads(params)
      },10000)
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  async mounted() {
    await this.startTimer();
    if(this.user && this.user.role !== 'admin') {
      const log = {
        action: 'ACESSOU_PAGINA_LINKS_PARA_DOWNLOAD'
      }
      await this.createUserActionLogs(log)
    }
    await this.handlGetEventActive();
  },

  async created() {
    this.loading = true
    this.reportsDownloadsMeta = {}
    this.reportsDownloads = []
    await this.handleChangeReportsDownloads({ sortIn: 'desc' })
    this.loading = false
    this.loadingText = 'Atualizando...'
    const breadcrumb = {
      page: 'Relatórios para download',
      subPage: {
        name: 'Relatórios para download',
        active: true
      },
      tabs: []
    }
    await this.setBreadcrumb(breadcrumb)
  }
}
</script>

<style lang='scss' scoped>
.loading{
  width: 2.3rem;
  height: 2.3rem;
}
.none-sublinhado {
  text-decoration: none;
}

.surveys-view {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.search {
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  /deep/ input {
    width: 256px;
  }
}
</style>
