<template>
  <div class='surveys-table'>
    <base-table
      :columns='columns'
      :data='reportsDownloads'
      :total-items='reportsDownloadsMeta.total'
      :start-items='reportsDownloadsMeta.skip + 1'
      :max-items='reportsDownloadsMeta.limit'
      @set-page='handleChangeUpdateLogs'
    >
      <template v-slot:body-actions='row'>
        <b-button
          variant="primary px-2"
          :href='row.item.url'
          target='_blank'
          v-if="row.item.status === 'processed'"
          @click='saveLog(row.item)'
        >
          Baixar relatório
        </b-button>

        <b-overlay
          v-else-if="row.item.status === 'processing'"
          :show="true"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
        >
          <b-button
            disabled
            variant="primary"
          >
            Baixar relatório
          </b-button>
        </b-overlay>

        <div v-else class="failed">
          <b>!</b>
        </div>
      </template>

      <template v-slot:body-type='row'>
        {{
          tipoRelatorio.find((x) => x.type === row.item.type)
            ? `${tipoRelatorio.find((x) => x.type === row.item.type).description}
               ${tipoRelatorioConsulTLegal.includes(row.item.type) ? row.item.company && row.item.company.name ? `- Consulta em modo empresa: ${row.item.company.name}` : '- Consulta em modo livre' : ''}`
            : '-'
        }}
      </template>

      <template v-slot:body-status='row'>
        {{statusList.find((x) => x.status === row.item.status).description}}
      </template>
    </base-table>
  </div>
</template>

<script>

import api from '../../utils/api'
import { mapActions } from 'vuex'
export default {
  name: 'ReportsDownloadsTable',
  props: {
    reportsDownloads: { type: Array, require: true },
    reportsDownloadsMeta: { type: Object, require: true }
  },
  async created() {
  },
  data() {
    return {
      statusList: [
        {
          status: 'processing',
          description: 'Arquivo em processamento',
        },
        {
          status: 'processed',
          description: 'Arquivo processado',
        },
        {
          status: 'failed',
          description: 'Arquivo falhou o processamento',
        },
      ],
      tipoRelatorio: [
        {
          type: 'ltcat',
          description: 'LTCAT',
        },
        {
          type: 'acgih',
          description: 'ACGIH',
        },
        {
          type: 'insalubrity',
          description: 'Laudo de Insalubridade',
        },
        {
          type: 'inventory',
          description: 'Inventário Químico',
        },
        {
          type: 'classificationRequestsxlsx',
          description: 'Pedidos de classificação em XLSX',
        },
        {
          type: 'classificationRequestscsv',
          description: 'Pedidos de classificação em CSV',
        },
        {
          type: 'surveysxlsx',
          description: 'Pesquisas em XLSX',
        },
        {
          type: 'surveyscsv',
          description: 'Pesquisas em CSV',
        },
        {
          type: 'frequencyOfUsexlsx',
          description: 'Frequência de uso em XLSX',
        },
        {
          type: 'frequencyOfUsecsv',
          description: 'Frequência de uso em CSV',
        },
        {
          type: 'chemicalAgentsResearchedxlsx',
          description: 'Agentes pesquisados em XLSX',
        },
        {
          type: 'chemicalAgentsResearchedcsv',
          description: 'Agentes pesquisados em CSV',
        },
        {
          type: 'logsApiSearchCasAgentxlsx',
          description: 'Registro de pesquisa por cas em XLSX',
        },
        {
          type: 'logsApiSearchCasAgentcsv',
          description: 'Registro de pesquisa por cas em CSV',
        },
        {
          type: 'quantitativeEvaluation',
          description: 'Avaliação quantitativa em XLSX',
        },
        {
          type: 'pcmso',
          description: 'PCMSO em XLSX',
        },
        {
          type: 'searchAgentsxlsx',
          description: 'Pesquisas de agentes em XLSX',
        },
        {
          type: 'searchAgentscsv',
          description: 'Pesquisas de agentes em CSV',
        },
        {
          type: 'agentsSelected',
          description: 'Agentes selecionados em XLSX',
        },
        {
          type: 'activecampaignTagSentxlsx',
          description: 'Tags enviadas em XLSX',
        },
        {
          type: 'activecampaignTagSentCsv',
          description: 'Tags enviadas em CSV',
        },
        {
          type: 'frequencyOfUsejson',
          description: 'Frequência de uso em Json',
        },
        {
          type: 'utmTrachingRegisterxlsx',
          description: 'Utm de cadastro',
        },
        {
          type: 'utmTrachingRegistercsv',
          description: 'Utm de cadastro',
        },
        {
          type: 'utmTrachingCheckoutxlsx',
          description: 'Utm de checkout',
        },
        {
          type: 'utmTrachingCheckoutcsv',
          description: 'Utm de checkout',
        },
        {
          type: 'logsTabxlsx',
          description: 'Histórico de alterações nas abas da consulta legal',
        },
        {
          type: 'companiesjson',
          description: 'Empresas cadastradas',
        },
      ],
      tipoRelatorioConsulTLegal: ['ltcat', 'acgih', 'insalubrity', 'quantitativeEvaluation', 'pcmso', 'agentsSelected'],
      columns: [
        { key: 'type', value: 'Arquivo' },
        { key: 'format', value: 'Formato do arquivo' },
        { key: 'status', value: 'Situação' },
        { key: 'data', value: 'Solicitado em' },
        { key: 'actions', value: 'Ação' }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
  },
  components: {
  },
  methods: {
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    handleChangeUpdateLogs(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.reportsDownloadsMeta.limit)
      }
      this.$emit('change-reportsDownloads', params)
    },
    async saveLog(url){
      if(this.user && this.user.role !== 'admin') {
        let action = ''
        switch (url.type) {
          case 'insalubrity': {
            action = 'DOWNLOAD_RELATORIO_LAUDO_INSALUBRIDADE'
            break;
          }
          case 'acgih': {
            action = 'DOWNLOAD_RELATORIO_ACGIH'
            break;
          }
          case 'ltcat': {
            action = 'DOWNLOAD_RELATORIO_LTCAT'
            break;
          }
          case 'quantitativeEvaluation': {
            action = 'DOWNLOAD_RELATORIO_AVALIACAO_QUANTITATIVA'
            break;
          }
          case 'pcmso': {
            action = 'DOWNLOAD_RELATORIO_PCMSO'
            break;
          }
          default: {
            action = 'DOWNLOAD_INVENTARIO_QUIMICO'
          }
        }

        const log = {
          action
        }
        await this.createUserActionLogs(log)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.loading{
  width: 1.2rem;
  height: 1.2rem;
}
.failed{
  background: red;
  color: white;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  text-align: center;
}

.api-key {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
